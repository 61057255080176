.hidescroll::-webkit-scrollbar {
    display: none;
}

.hidescroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

body {
    user-select: none;
}

a {
    color: inherit;
}

a:visited {
    color: inherit;
}

@font-face {
    font-family: "THICCCBOI";
    src: url('res/fonts/THICCCBOI-SemiBold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "THICCCBOI";
    src: url('res/fonts/THICCCBOI-ExtraBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
